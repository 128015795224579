import React, { useState, useEffect } from "react";
import ReactVivus from "react-vivus";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import IconMenu from "@images/menu.svg";
import NavigationShape from "@images/navigation.svg";
import BehanceLogo from "@images/behance.svg";

import {
  Wrapper,
  Hamburger,
  Close,
  Icon,
  NavList,
  NavListItem,
  HoverShape,
  Behance,
} from "./styles.js";

const Header = ({ navItems, behanceUrl }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuHover, setMenuHover] = useState(10);

  useEffect(() => {
    menuOpened
      ? (document.querySelector("html").style.overflowY = "hidden")
      : (document.querySelector("html").style.overflowY = "visible");
  }, [menuOpened]);

  return (
    <Wrapper>
      <Hamburger onMouseDown={() => setMenuOpened(true)}>
        <Icon src={IconMenu} />
      </Hamburger>
      <NavList
        isOpened={menuOpened}
        onMouseDown={() => setTimeout(() => setMenuOpened(false), 500)}
      >
        {navItems.map((item, index) => (
          <NavListItem
            key={item.id}
            onMouseEnter={() => setMenuHover(index)}
            onMouseLeave={() => setMenuHover(10)}
          >
            {menuHover === index && (
              <HoverShape>
                <ReactVivus
                  id={`nav-shape${index}`}
                  option={{
                    file: NavigationShape,
                    start: "autostart",
                    duration: 50,
                    animTimingFunction: "EASE",
                    type: "sync",
                  }}
                />
              </HoverShape>
            )}
            <AnchorLink to={item.url} title={item.name}>
              <span>{item.name}</span>
            </AnchorLink>
          </NavListItem>
        ))}
      </NavList>
      <Close visible={menuOpened} onMouseDown={() => setMenuOpened(false)}>
        X
      </Close>
      <a href={behanceUrl} target="_blank" rel="noopener noreferrer">
        <Behance src={BehanceLogo} />
      </a>
    </Wrapper>
  );
};

Header.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  behanceUrl: PropTypes.string,
};

Header.defaultProps = {
  navItems: [],
  behanceUrl: "#",
};

export default Header;
