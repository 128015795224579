import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";

export const Wrapper = styled.nav`
  z-index: 5000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;

  ${({ theme }) => theme.media.tablet} {
    top: 20px;
    height: auto;
    padding: 5px 0;
  }

  ${({ theme }) => theme.media.desktop} {
    top: 50px;
    padding: 10px 0;
  }

  ${({ theme }) => theme.media.tv} {
    top: 75px;
    padding: 15px 0;
  }
`;

export const Hamburger = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;

  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`;

export const Close = styled.div`
  cursor: pointer;
  transition: transform 0.7s ease, opacity 0.7s ease;
  transition-delay: 0.2s;
  position: fixed;
  top: 40px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 45px;
  background: black;
  color: white;
  font-family: "gotham", sans-serif;
  font-size: 30px;
  line-height: 1;

  ${({ visible }) =>
    visible
      ? css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
          transition-delay: 0.4s;
        `
      : css`
          transform: translate3d(0, 100px, 0);
          opacity: 0;
          pointer-events: none;
        `}

  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`;

export const Icon = styled(SVG)`
  position: relative;
  display: flex;
  flex-basis: 20px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

export const NavList = styled.ul`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 180px;
  font-size: 34px;
  background: ${({ theme }) => theme.colors.white};
  transform: translate3d(${({ isOpened }) => (isOpened ? "0" : "-100%")}, 0, 0);
  transition: 0.4s ease;

  ${({ theme }) => theme.media.tablet} {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    padding-top: 0;
    background: none;
    transform: translate3d(0, 0, 0);
    font-size: 18px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 22px;
  }

  ${({ theme }) => theme.media.tv} {
    font-size: 24px;
  }
`;

export const NavListItem = styled.li`
  position: relative;
  display: block;
  text-transform: uppercase;
  text-align: left;

  a {
    display: block;
    padding: 20px 50px;
  }

  ${({ theme }) => theme.media.tablet} {
    display: inline-block;

    a {
      display: block;
      padding: 18px 25px;
    }

    &:first-child a {
      padding-left: 0;
    }
  }
`;

export const Behance = styled(SVG)`
  width: 28px;

  ${({ theme }) => theme.media.tablet} {
    width: 32px;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 48px;
  }

  ${({ theme }) => theme.media.tv} {
    width: 52px;
  }
`;

export const HoverShape = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -80px;
  width: 160px;
  pointer-events: none;
`;
