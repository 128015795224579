export const fonts = {
  ubsHeader1: () => `
        font-family: 'Frutiger-45Light', sans-serif;
        font-size: 3rem;
        line-height: 1.08333;
    `,
  ubsHeader2: () => `
        font-family: 'Frutiger-45Light', sans-serif;
        font-size: 2.375rem;
        line-height: 1.15789;
    `,
  ubsHeader5: () => `
        font-family: 'Frutiger-55Roman', sans-serif;
        font-size: 1.125rem;
        line-height: 1.33333;
    `,
  ubsHeader6: () => `
        font-family: 'Frutiger-55Roman', sans-serif;
        font-size: 1rem;
        line-height: 1.25;
    `,
  ubsBody2: () => `
        font-family: 'Frutiger-55Roman', sans-serif;
        font-size: 14px;
        line-height: 20px;
    `,
  ubsBody2bold: () => `
        font-family: 'Frutiger-45Light', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
    `,
  ubsBody3: () => `
        font-family: 'Frutiger-55Roman', sans-serif;
        font-size: 12px;
        line-height: 16px;
    `,
  ubsNumber3: () => `
        font-family: 'Frutiger-45Light', sans-serif;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.33333;
    `,
};
