export const colors = {
  ghost: "transparent",
  white: "#ffffff",
  white2: "#fafafa",
  black: "#000000",
  black2: "#161616",

  grey: "#b1aba5",

  seashell: "#fff6ee",
  seashell2: "#e5ded9",

  red: "#e6001c",
  green: "#35be25",
};
