import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import { Wrapper, Text, Link } from "./styles.js";

const Footer = ({ text }) => {
  return (
    <Wrapper>
      <Text>
        <ReactMarkdown
          renderers={{
            link: (props) => (
              <Link href={props.href} target="_blank">
                {props.children}
              </Link>
            ),
          }}
          children={text}
        />
      </Text>
    </Wrapper>
  );
};

Footer.propTypes = {
  text: PropTypes.string,
};

export default Footer;
