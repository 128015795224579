import { colors } from "./colors";
import { fonts } from "./fonts";

export const mainTheme = {
  colors,
  fonts,
  media: {
    tablet: "@media (min-width: 760px)",
    desktop: "@media (min-width: 1280px)",
    bigDesktop: "@media (min-width: 1580px)",
    tv: "@media (min-width: 1780px)",
  },
  margins: {
    maxWidth: "1700px",
    phonePadding: `25px`,
    tabletPadding: `30px`,
    desktopPadding: `80px`,
    bigDesktopPadding: `120px`,
    tvPadding: `170px`,
  },
};
