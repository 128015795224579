import styled from "styled-components";

export const Wrapper = styled.footer`
  margin: 0 auto;
  padding: 80px 0 90px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    padding: 100px 0 120px;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 130px 0 150px;
  }
`;

export const Text = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 12px;
  font-family: "gotham", sans-serif;
  line-height: 1.61;

  ${({ theme }) => theme.media.tablet} {
    font-size: 16px;
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: 18px;
  }
`;

export const Link = styled.a`
  transition: color 0.3s ease;

  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;
