export const general = {
  url: "https://www.illustrators.pl",
  name: "illustratorspl",
  address: "Des Voux Road 152 / Central , Hong Kong",
  emails: ["hello@illustrators.pl"],
  phones: ["+48 576 283 920"],
  behance: "https://www.behance.com",
  fbId: "100923375406167",
  title: "Illustrators | Great artists in one place",
  description:
    "Meet our artists and discover the variety of their individual styles.",
  author: "DB",
  keywords: [
    "illustrators",
    "graphic",
    "designers",
    "hire",
    "find",
    "artists",
    "art",
    "illustrations",
    "illustration",
    "marketing",
    "freelancers",
    "design",
    "advertising",
    "publishing",
    "worldwide",
    "polska",
    "poland",
    "commercials",
  ],
  footer:
    "All rights reserved by illustrators.pl | 2021\n\nProudly created and developed by [Gravity Warsaw](http://gravitywarsaw.com)",
};
