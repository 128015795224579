export const navigation = [
  {
    id: "home",
    name: "Home",
    url: "/#home",
  },
  {
    id: "artists",
    name: "Artists",
    url: "/#artists",
  },
  {
    id: "mission",
    name: "Our Mission",
    url: "/#our-mission",
  },
  {
    id: "contact",
    name: "Contact Us",
    url: "/#contact",
  },
];
