import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

import "@theme/sanitize.css";
import "@theme/fonts.css";
import { mainTheme } from "@theme/theme";

import Header from "@organisms/Header";
import Footer from "@organisms/Footer";

import { general } from "@content/general";
import { navigation } from "@content/navigation";

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
    width: 100vw;
  }

  body {
    position: relative;
    overflow-x: hidden;
    width: 100vw;
    margin: 0;
    padding: 0 ${({ theme }) => theme.margins.phonePadding};
    background: ${({ theme }) => theme.colors.seashell};
    color: ${({ theme }) => theme.colors.black};
    font-family: "giveny", serif;
    font-weight: normal;
  }

  #portal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9000;
  }

  ${({ theme }) => theme.media.tablet} {
    body {
      padding: 0 ${({ theme }) => theme.margins.tabletPadding};
    }
  }

  ${({ theme }) => theme.media.desktop} {
    body {
      padding: 0 ${({ theme }) => theme.margins.desktopPadding};
    }
  }

  ${({ theme }) => theme.media.bigDesktop} {
    body {
      padding: 0 ${({ theme }) => theme.margins.bigDesktopPadding};
    }
  }

  ${({ theme }) => theme.media.tv} {
    body {
      padding: 0 ${({ theme }) => theme.margins.tvPadding};
    }
  }
`;

const StyledMainContent = styled.main`
  position: relative;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.margins.maxWidth};
`;

const Main = ({ children }) => {
  return (
    <ThemeProvider theme={mainTheme}>
      <>
        <GlobalStyle />
        <StyledMainContent>
          <Header navItems={navigation} behanceUrl={general.behance} />
          {children}
          <Footer text={general.footer} />
        </StyledMainContent>
      </>
    </ThemeProvider>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Main;
